@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+US+Modern:wght@100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+US+Modern:wght@100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+US+Modern:wght@100..400&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+US+Modern:wght@100..400&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+US+Modern:wght@100..400&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Syne:wght@400..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    @apply transition-colors duration-300;
}

html,
body {
    @apply h-full m-0 p-0;
}

#root {
    @apply min-h-screen h-screen flex flex-col relative;
    @apply overflow-x-hidden;
}

body {
    @apply bg-bg_light_primary;
}

body.dark {
    @apply bg-bg_dark_primary;
}

body.dark .chrome-picker {
    background-color: #2c2c2c !important; /* Tailwind dark background color */
    color: white !important;
}

::-webkit-scrollbar,
.element::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    border-radius: 0;
    background: #ebeaed;
    scrollbar-width: thin;
    scrollbar-color: #3d465d;
}

::-webkit-scrollbar-thumb {
    border: 1px solid #78787a5b;
}

.editable-aria-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 0;
    background: #ebeaed;
    scrollbar-width: thin;
    scrollbar-color: #3d465d;
    border-radius: 20px;
}

.editable-aria-scroll::-webkit-scrollbar-thumb {
    border: 10px solid #78787a;
    border-radius: 20px;
}

.fade-container {
    transition: opacity 0.4s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.image-container {
    overflow: hidden;
    position: relative;
}

.rCS1w3zcxh {
    z-index: 0 !important;
}

.rs-slider-bar {
    background-color: #d6d6d6 !important; /* Custom bar color */
}

.rs-slider-progress-bar {
    background-color: #353f5c !important; /* Custom progress color */
}

.rs-slider-handle {
    top: -75% !important;
}

.rs-slider-handle:before {
    background-color: #353f5c !important; /* Custom handle color */
    border-color: #ebeaed !important; /* Custom handle border color */
    width: 14px !important;
    height: 14px !important;
    box-shadow: 0px 0px 1px 0px #0000001a, 0px 2px 2px 0px #00000017, 0px 4px 2px 0px #0000000d, 0px 7px 3px 0px #00000003, 0px 10px 3px 0px #00000000;
}
