.switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 32px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s ease;
    background: linear-gradient(to bottom, #73bbff, #a2d1fd);
}

.switch::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    opacity: 0;
    background: linear-gradient(to top, #2b3347, #181d27);
    transition: all 0.5s ease;
}

#switchThemeMode.dark .switch::before {
    opacity: 1;
}

.switch .border {
    position: absolute;
    top: calc(2px * -1);
    left: calc(2px * -1);
    width: calc(100% + 2px * 2);
    height: calc(100% + 2px * 2);
    border-radius: 50px;
    background: linear-gradient(to bottom, #a2d1fd, #cde7ff);
    z-index: -1;
    transition: all 0.5s ease;
}

.switch .border::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(to bottom, #000000, #6c7384);
    opacity: 0;
    transition: all 0.5s ease;
}

#switchThemeMode.dark .switch .border::after {
    opacity: 1;
}

.switch input {
    display: none;
}

.sunmoon {
    position: absolute;

    border-radius: 50%;
    transition: all 0.5s ease;
    left: 5px;
    z-index: 1;
    background-color: #ffc187;
    box-shadow: 0px 0px 11.7px 0px #ffc187, 0px 0px 20px 0px #ffc18768, -2px -2px 5px 0px #ffab5c inset;
}

#switchThemeMode.dark .sunmoon {
    left: calc(100% - 26px - 5px);
    background-color: #dee5f3;
    box-shadow: 0px 0px 51.7px 0px #dee5f3;
}

.darkside {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: #ffc187;
    transition: all 0.5s ease;
}

#switchThemeMode.dark .darkside {
    background-color: #565c6b;
}

.clouds {
    border-radius: 50px;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.cloud {
    position: absolute;
    width: 60%;
    transition: all 0.5s ease;
}

.cloud_1 {
    bottom: -50%;
    left: 0px;
}

.cloud_2 {
    bottom: -35%;
    left: 15px;
}

.cloud_3 {
    bottom: -35%;
    right: -5px;
}

.cloud_4 {
    bottom: -10%;
    right: -17px;
}

#switchThemeMode.dark .cloud_1 {
    bottom: -35%;
    left: -110px;
}

#switchThemeMode.dark .cloud_2 {
    bottom: -15%;
    left: -110px;
    transition: all 0.7s ease;
}

#switchThemeMode.dark .cloud_3 {
    bottom: -15%;
    right: -110px;
}

#switchThemeMode.dark .cloud_4 {
    bottom: -5%;
    right: -110px;
    transition: all 0.7s ease;
}

.stars {
    position: absolute;
    top: 150%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.5s ease;
}

#switchThemeMode.dark .stars {
    top: 50%;
}
